import * as React from 'react';
import { invariantIntlContext } from '../utils';
import { Context } from './injectIntl';
var DisplayName;
(function (DisplayName) {
    DisplayName["formatDate"] = "FormattedDate";
    DisplayName["formatTime"] = "FormattedTime";
    DisplayName["formatNumber"] = "FormattedNumber";
})(DisplayName || (DisplayName = {}));
export default function createFormattedComponent(name) {
    const Component = props => (React.createElement(Context.Consumer, null, intl => {
        invariantIntlContext(intl);
        const formattedValue = intl[name](props.value, props);
        if (typeof props.children === 'function') {
            return props.children(formattedValue);
        }
        const Text = intl.textComponent || React.Fragment;
        return React.createElement(Text, null, formattedValue);
    }));
    Component.displayName = DisplayName[name];
    return Component;
}
